import { t } from "@whitelabel-engine/i18n";
import Image from "@whitelabel-engine/ui/Image";
import type { DialogProps } from "@whitelabel-engine/ui/Modals/Dialog";
import Dialog from "@whitelabel-engine/ui/Modals/Dialog";
import Text from "@whitelabel-engine/ui/Text";
import type { Colors } from "@whitelabel-engine/ui/utils/colors";
import { type FC, type MouseEvent, useState } from "react";

import DynamicButton from "../Dynamic/LoadingButton";
import IconRender from "../IconRender";
import InputQuantityWithPercentageButtons from "../InputQuantityWithPercentageButtons";
import { formatNumber } from "../utils/format";

export type CommitWithdrawDepositStakeModalProps = DialogProps & {
  initAmount: bigint;
  maxPerWallet: bigint;
  minPerWallet?: bigint;
  backgroundColor?: Colors;
  borderColor?: Colors;
  color?: Colors;
  onClose?: (event?: MouseEvent<HTMLButtonElement>) => void;
  onClick?: (amount: bigint) => void;
  open?: boolean;
  showCloseButton?: boolean;
  title?: string;
  warningMessage?: string;
  balance?: number | string;
  committed?: number | string;
  claimable?: number | string;
  callToActionText?: string;
  isLoading?: boolean;
  tokenIcon?: string;
};

const CommitWithdrawDepositStakeModal: FC<
  CommitWithdrawDepositStakeModalProps
> = ({
  backgroundColor = "primary",
  balance = 0,
  borderColor = "transparent",
  callToActionText = t`Commit`,
  claimable = 0,
  color = "light",
  committed = 0,
  initAmount,
  isLoading,
  maxPerWallet,
  minPerWallet = 1n,
  onClick,
  onClose,
  opacity = 50,
  open,
  showCloseButton,
  title = t`Commit`,
  tokenIcon,
  warningMessage = t`Claimable rewards will be automatically claimed and re-committed for you.`,
  ...props
}) => {
  const [amount, setAmount] = useState<bigint>(initAmount);

  const handleOnClose = () => {
    setAmount(initAmount);
    onClose?.();
  };

  const handleOnClick = () => {
    onClick?.(amount);
  };

  return (
    <Dialog
      open={open}
      backgroundColor={backgroundColor}
      wrapperClassName={[
        "max-w-[320px]",
        "sm:max-w-[600px]",
        "flex",
        "flex-col",
        "gap-8",
        "justify-center",
        "items-center",
        "border",
        "p-4",
        `border-${borderColor}`,
        `text-${color}`,
      ].join(" ")}
      hideCloseButton
      opacity={opacity}
      {...props}
    >
      <div className="flex w-full flex-row items-center justify-between">
        {title && (
          <Text weight="bold" variant="h4" className="uppercase">
            {title}
          </Text>
        )}

        {showCloseButton && (
          <DynamicButton
            disabled={isLoading}
            variant="text"
            onClick={handleOnClose}
          >
            <IconRender
              className={isLoading ? "!cursor-not-allowed" : "cursor-pointer"}
              icon="Close"
              width={14}
              color="light"
            />
          </DynamicButton>
        )}
      </div>

      <div className="flex w-full max-w-[360px] flex-col gap-4">
        <InputQuantityWithPercentageButtons
          amount={amount}
          minPerWallet={minPerWallet}
          maxPerWallet={maxPerWallet}
          onChange={setAmount}
          rounded="rounded-none"
          backgroundColor="primary"
          disabled={isLoading}
        />

        <div className="mt-4 flex w-full flex-row justify-between">
          <Text variant="span">{t`$BONDLY in wallet`}</Text>
          <div className="flex flex-row gap-2">
            <Text variant="span" weight="bold">
              {formatNumber(balance)}
            </Text>
            {tokenIcon ? (
              <Image
                className="w-[24px] rounded-full"
                width={15}
                height={15}
                alt="Token Symbol"
                src={tokenIcon}
              />
            ) : null}
          </div>
        </div>
        <div className="flex w-full flex-row justify-between">
          <Text variant="span">{t`Committed`}</Text>
          <div className="flex flex-row gap-2">
            <Text variant="span" weight="bold">
              {formatNumber(committed)}
            </Text>
            {tokenIcon ? (
              <Image
                className="w-[24px] rounded-full"
                width={15}
                height={15}
                alt="Token Symbol"
                src={tokenIcon}
              />
            ) : null}
          </div>
        </div>
        <div className="flex w-full flex-row justify-between">
          <Text variant="span">{t`Claimable Rewards`}</Text>
          <div className="flex flex-row gap-2">
            <Text variant="span" weight="bold">
              {formatNumber(claimable)}
            </Text>
            {tokenIcon ? (
              <Image
                className="w-[24px] rounded-full"
                width={15}
                height={15}
                alt="Token Symbol"
                src={tokenIcon}
              />
            ) : null}
          </div>
        </div>
      </div>

      {warningMessage ? (
        <div className="flex w-full max-w-[480px] items-center justify-center gap-4">
          <IconRender color="light" icon="Info" width={20} />
          <Text variant="p" weight="medium">
            {warningMessage}
          </Text>
        </div>
      ) : null}

      <div className="flex w-72">
        <DynamicButton
          variant="contained"
          color="light"
          className="w-full"
          loading={isLoading}
          onClick={handleOnClick}
        >
          {callToActionText}
        </DynamicButton>
      </div>
    </Dialog>
  );
};

export default CommitWithdrawDepositStakeModal;
