import { useEffect, useState } from "react";

export type Countdown = {
  years: string;
  months: string;
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
};

type UseCountdownState = Countdown & { hasEnded: boolean };

export function hasCountdownEnded(targetDate: Date) {
  const now = new Date().getTime();
  const diff = targetDate.getTime() - now;

  return {
    diff,
    hasEnded: diff <= 0,
  };
}

const useCountdown = (
  targetDate: Date,
  hasEnded = false,
): UseCountdownState => {
  const [timeLeft, setTimeLeft] = useState<UseCountdownState>({
    hasEnded,
    years: "00",
    months: "00",
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const { diff, hasEnded } = hasCountdownEnded(targetDate);

      if (hasEnded) {
        setTimeLeft((prevState) => ({ ...prevState, hasEnded }));
        clearInterval(interval);
        return;
      }

      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30);
      const years = Math.floor(months / 12);

      setTimeLeft({
        hasEnded,
        years: years % 12 < 10 ? `0${years % 12}` : `${years % 12}`,
        months: months % 12 < 10 ? `0${months % 12}` : `${months % 12}`,
        days: days % 30 < 10 ? `0${days % 30}` : `${days % 30}`,
        hours: hours % 24 < 10 ? `0${hours % 24}` : `${hours % 24}`,
        minutes: minutes % 60 < 10 ? `0${minutes % 60}` : `${minutes % 60}`,
        seconds: seconds % 60 < 10 ? `0${seconds % 60}` : `${seconds % 60}`,
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return timeLeft;
};

export default useCountdown;
