import type { Address } from "@whitelabel-engine/typings/contract";
import { erc20Abi, maxUint256, zeroAddress } from "viem";
import { useBalance } from "wagmi";

import useActiveWagmi from "./useActiveWagmi";
import useContractReadWithTransactionWatch from "./useContractReadWithTransactionWatch";
import useContractWriteWithTransactionWatch from "./useContractWriteWithTransactionWatch";
import useWatchContract from "./useWatchContract";

export const useApproveTokenAllowance = (
  tokenAddress: Address,
  spender: Address,
  mintAmount: bigint,
  onSuccess?: () => void,
  onError?: () => void,
  enableSmartContractInteraction: boolean = true,
) => {
  const { account } = useActiveWagmi();
  const isNativeToken = tokenAddress === zeroAddress;
  const enableSmartContractQuery =
    Boolean(account) && !isNativeToken && enableSmartContractInteraction;

  const tokenContract = {
    abi: erc20Abi,
    address: tokenAddress,
    watch: true,
    enabled: enableSmartContractQuery,
  };

  const { data: tokenBalance, queryKey: queryKeyBalance } = useBalance({
    address: account,
    token: tokenAddress,
  });
  const _tokenBalanceOrMaxValue = tokenBalance?.value ?? maxUint256;
  const maxApprovalValuePerWallet =
    mintAmount > _tokenBalanceOrMaxValue ? mintAmount : _tokenBalanceOrMaxValue;
  const enabled = Boolean(account && spender);
  const {
    data: allowance,
    isError,
    queryKey: queryKeyAllowance,
  } = useContractReadWithTransactionWatch(
    {
      ...tokenContract,
      functionName: "allowance",
      args: [account, spender],
    },
    enabled,
  );
  useWatchContract([queryKeyBalance, queryKeyAllowance]);

  const { write: _onApprove, ...contractWriteResults } =
    useContractWriteWithTransactionWatch(
      enableSmartContractInteraction
        ? {
            ...tokenContract,
            functionName: "approve",
            args: [spender, maxApprovalValuePerWallet],
          }
        : {},
      onSuccess,
      onError,
    );

  const onApprove =
    typeof allowance === "undefined" || allowance < mintAmount || isError
      ? _onApprove
      : onSuccess;

  return isNativeToken
    ? {
        ...contractWriteResults,
        allowance: maxUint256,
        onApprove: onSuccess,
        isApproveRequired: false,
        isError: false,
        isLoading: false,
        isSuccess: true,
        reset: () => {},
      }
    : {
        ...contractWriteResults,
        allowance,
        onApprove,
        isApproveRequired:
          typeof allowance !== "undefined" && allowance < mintAmount,
      };
};
