import { useEffect } from "react";
import { useWaitForTransactionReceipt, useWriteContract } from "wagmi";

const useContractWriteWithTransactionWatch = (
  config: any,
  onSuccess?: Function,
  onError?: Function,
) => {
  const { writeContract, writeContractAsync, ...contractWrite } =
    useWriteContract();
  const waitForTransaction = useWaitForTransactionReceipt({
    hash: contractWrite?.data,
    query: {
      enabled: Boolean(contractWrite?.data),
    },
  });
  const write = (extracConfig = {}) => {
    writeContract({ ...config, ...extracConfig });
  };
  const writeAsync = (extracConfig = {}) => {
    writeContractAsync({ ...config, ...extracConfig });
  };
  const isError = contractWrite?.isError || waitForTransaction?.isError;
  const isSuccess = contractWrite?.isSuccess && waitForTransaction?.isSuccess;
  const isLoading = contractWrite?.isPending || waitForTransaction?.isLoading;
  const reset = () => {
    contractWrite.reset();
    waitForTransaction.refetch();
  };

  const response = {
    ...contractWrite,
    ...waitForTransaction,
    reset,
    data: {
      ...contractWrite,
      ...(waitForTransaction?.data ?? {}),
    },
    isError,
    isSuccess,
    isLoading,
    error: contractWrite?.error || waitForTransaction?.error,
    write,
    writeAsync,
  };

  useEffect(() => {
    if (response.isSuccess && !response.error && onSuccess) {
      return onSuccess(response.data);
    }
    if (response.isError && onError) {
      return onError(response.error);
    }
  }, [response.isSuccess, response.isError]);

  return response;
};

export default useContractWriteWithTransactionWatch;
