import { t } from "@whitelabel-engine/i18n";
import Image from "@whitelabel-engine/ui/Image";
import type { DialogProps } from "@whitelabel-engine/ui/Modals/Dialog";
import Dialog from "@whitelabel-engine/ui/Modals/Dialog";
import Text from "@whitelabel-engine/ui/Text";
import type { Colors } from "@whitelabel-engine/ui/utils/colors";
import { type FC, type MouseEvent } from "react";

import DynamicButton from "../Dynamic/LoadingButton";
import IconRender from "../IconRender";
import { formatNumber } from "../utils/format";

export type CoumpoundDepositStakeModalProps = DialogProps & {
  backgroundColor?: Colors;
  borderColor?: Colors;
  color?: Colors;
  committed?: number;
  isLoading?: boolean;
  message?: string;
  networks?: any[];
  onClose?: (event?: MouseEvent<HTMLButtonElement>) => void;
  onClick?: () => void;
  open?: boolean;
  rewards?: number;
  showCloseButton?: boolean;
  title?: string;
  tokenIcon?: string;
};

const CoumpoundDepositStakeModal: FC<CoumpoundDepositStakeModalProps> = ({
  backgroundColor = "primary",
  borderColor = "transparent",
  color = "light",
  committed = 0,
  isLoading,
  message = t`Your rewards will be claimed and automatically re-committed for you.`,
  networks,
  onClose,
  onClick,
  opacity = 50,
  open,
  rewards = 0,
  showCloseButton,
  title = t`Coumpound`,
  tokenIcon,
  ...props
}) => {
  const handleOnClose = () => {
    onClose?.();
  };

  return (
    <Dialog
      open={open}
      backgroundColor={backgroundColor}
      wrapperClassName={[
        "max-w-[320px]",
        "sm:max-w-[640px]",
        "flex",
        "flex-col",
        "gap-8",
        "justify-center",
        "items-center",
        "border",
        "p-4",
        `border-${borderColor}`,
        `text-${color}`,
      ].join(" ")}
      hideCloseButton
      opacity={opacity}
      {...props}
    >
      <div className="flex w-full flex-row items-center justify-between">
        {title && (
          <Text weight="bold" variant="h4" className="uppercase">
            {title}
          </Text>
        )}

        {showCloseButton && (
          <DynamicButton
            disabled={isLoading}
            variant="text"
            onClick={handleOnClose}
          >
            <IconRender
              className={isLoading ? "!cursor-not-allowed" : "cursor-pointer"}
              icon="Close"
              width={14}
              color="light"
            />
          </DynamicButton>
        )}
      </div>

      <div className="flex w-full items-center justify-center gap-4">
        <Text variant="p" weight="medium">
          {message}
        </Text>
      </div>

      <div className="flex w-full max-w-[360px] flex-col gap-4">
        <div className="mt-4 flex w-full flex-row justify-between">
          <Text variant="span">{t`Rewards`}</Text>
          <div className="flex flex-row gap-2">
            <Text variant="span" weight="bold">
              {formatNumber(rewards)}
            </Text>
            {tokenIcon ? (
              <Image
                className="max-h-[24px] w-[24px] rounded-full"
                width={15}
                height={15}
                alt="Token Symbol"
                src={tokenIcon}
              />
            ) : null}
          </div>
        </div>
        <div className="flex w-full flex-row justify-between">
          <Text variant="span">{t`Current Committed Balance`}</Text>
          <div className="flex flex-row gap-2">
            <Text variant="span" weight="bold">
              {formatNumber(committed)}
            </Text>
            {tokenIcon ? (
              <Image
                className="max-h-[24px] w-[24px] rounded-full"
                width={15}
                height={15}
                alt="Token Symbol"
                src={tokenIcon}
              />
            ) : null}
          </div>
        </div>

        <hr />

        <div className="flex w-full flex-row justify-between">
          <Text variant="span">{t`New Committed Balance`}</Text>
          <div className="flex flex-row gap-2">
            <Text variant="span" weight="bold">
              {formatNumber(rewards + committed)}
            </Text>
            {tokenIcon ? (
              <Image
                className="max-h-[24px] w-[24px] rounded-full"
                width={15}
                height={15}
                alt="Token Symbol"
                src={tokenIcon}
              />
            ) : null}
          </div>
        </div>
      </div>

      <div className="flex w-72">
        <DynamicButton
          variant="contained"
          color="light"
          className="w-full"
          loading={isLoading}
          onClick={onClick}
        >
          {t`COMPOUND`}
        </DynamicButton>
      </div>
    </Dialog>
  );
};

export default CoumpoundDepositStakeModal;
