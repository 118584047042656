import InfoIcon from "@whitelabel-engine/ui/Icon/Info";
import type {
  ForwardedRef,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  ReactNode,
} from "react";
import React, { forwardRef, useMemo } from "react";

import type { Colors } from "../utils/colors";

export type TextFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  size?: "small" | "medium" | "large";
  variant?: "regular" | "raw";
  color?: Colors;
  placeholderColor?: Colors;
  startSlot?: ReactNode | null;
  endSlot?: ReactNode | null;
  error?: boolean;
  errorMessage?: string;
  label?: ReactNode | null;
  errorLabelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  fullWidth?: boolean;
  required?: boolean;
  wrapperClassName?: string;
  inputWrapperClassName?: string;
  hideSlotSeparator?: boolean;
  "data-testid"?: string;
};

const TextField = forwardRef(function TextField(
  {
    startSlot = null,
    endSlot = null,
    error = false,
    variant = "regular",
    color = "secondary",
    placeholderColor,
    errorMessage = "",
    size,
    label,
    errorLabelProps = {},
    fullWidth = false,
    required = false,
    hideSlotSeparator = false,
    className = "",
    wrapperClassName = "",
    inputWrapperClassName = "",
    ...props
  }: TextFieldProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const isVariantRegular = variant === "regular";
  const inputClassName = useMemo(
    () =>
      [
        "outline-0",
        isVariantRegular && "h-full",
        isVariantRegular && "w-full",
        isVariantRegular && "h-10",
        "bg-transparent",
        "placeholder:text-placeholder",
        `placeholder:text-${placeholderColor || color}`,
        error ? "text-danger" : `text-${color}`,
        size === "small" && "text-base",
        size === "medium" && "text-lg",
        size === "large" && "text-xl",
        props.disabled ? "opacity-50" : "",
        props.disabled ? "cursor-not-allowed" : "",
        ...(startSlot
          ? [
              hideSlotSeparator ? "" : "border-l-solid",
              hideSlotSeparator ? "" : "border-l-2",
              hideSlotSeparator ? "" : "border-neutral",
              "pl-4",
              "ml-2",
            ]
          : []),
        ...(endSlot
          ? [
              hideSlotSeparator ? "" : "border-r-solid",
              hideSlotSeparator ? "" : "border-r-2",
              hideSlotSeparator ? "" : "border-neutral",
              "pr-4",
              "mr-2",
            ]
          : []),
        className,
      ]
        .filter((i) => i)
        .join(" "),
    [error, color, size, startSlot, endSlot, className, hideSlotSeparator],
  );

  return (
    <div
      className={[
        "relative",
        "flex",
        "flex-col",
        "items-center",
        "justify-center",
        fullWidth ? "w-full" : "",
        props.disabled ? "opacity-50" : "",
        props.disabled ? "cursor-not-allowed" : "",
        wrapperClassName,
      ]
        .filter((i) => i)
        .join(" ")}
    >
      {typeof label === "string" ? (
        <label
          className={[
            "flex",
            "w-full",
            "pb-1",
            "text-left",
            "items-center",
            error ? "text-danger" : `text-${color}`,
            error ? "font-semibold" : `text-normal`,
          ].join(" ")}
        >
          {label}
          {required && (
            <InfoIcon
              className="ml-2 inline-flex items-center text-xs font-semibold"
              color={error ? "danger" : color}
              width={13}
            />
          )}
        </label>
      ) : (
        label
      )}
      <div
        className={[
          "flex",
          "w-full",
          "items-center",
          "justify-between",
          isVariantRegular && "border-neutral",
          isVariantRegular && "rounded",
          isVariantRegular && "min-h-[50px]",
          isVariantRegular && "border-2",
          isVariantRegular && "border-solid",
          isVariantRegular && "py-2",
          isVariantRegular && "px-4",
          inputWrapperClassName,
        ]
          .filter((i) => i)
          .join(" ")}
      >
        {startSlot && <div className="w-fit">{startSlot}</div>}
        <input ref={ref} className={inputClassName} {...props} />
        {endSlot && <div className="w-fit">{endSlot}</div>}
      </div>
      {error && (
        <label
          {...errorLabelProps}
          className={[
            "text-danger",
            "absolute",
            "-bottom-6",
            "py-1",
            "text-xs",
            "font-semibold",
            errorLabelProps?.className ?? "",
          ].join(" ")}
        >
          {errorMessage}
        </label>
      )}
    </div>
  );
});

export default TextField;
