import { t } from "@whitelabel-engine/i18n";
import type { DialogProps } from "@whitelabel-engine/ui/Modals/Dialog";
import Dialog from "@whitelabel-engine/ui/Modals/Dialog";
import Text from "@whitelabel-engine/ui/Text";
import type { Colors } from "@whitelabel-engine/ui/utils/colors";
import { type FC, type MouseEvent } from "react";

import DynamicButton from "../Dynamic/LoadingButton";
import IconRender from "../IconRender";
import { formatNumber } from "../utils/format";

export type ClaimStakeModalProps = DialogProps & {
  amount: string | number;
  backgroundColor?: Colors;
  borderColor?: Colors;
  color?: Colors;
  isLoading?: boolean;
  message?: string;
  onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
  onClose?: (event?: MouseEvent<HTMLButtonElement>) => void;
  open?: boolean;
  showCloseButton?: boolean;
  title?: string;
};

const ClaimStakeModal: FC<ClaimStakeModalProps> = ({
  amount,
  backgroundColor = "primary",
  borderColor = "transparent",
  color = "light",
  isLoading,
  message = t`Claimable Rewards`,
  onClick,
  onClose,
  opacity = 50,
  open,
  showCloseButton,
  title = t`Claim`,
  ...props
}) => {
  const handleOnClose = () => {
    onClose?.();
  };

  return (
    <Dialog
      open={open}
      backgroundColor={backgroundColor}
      wrapperClassName={[
        "max-w-[320px]",
        "sm:max-w-[600px]",
        "flex",
        "flex-col",
        "gap-8",
        "justify-center",
        "items-center",
        "border",
        "p-4",
        `border-${borderColor}`,
        `text-${color}`,
      ].join(" ")}
      hideCloseButton
      opacity={opacity}
      {...props}
    >
      <div className="flex w-full flex-row items-center justify-between">
        {title && (
          <Text weight="bold" variant="h4" className="uppercase">
            {title}
          </Text>
        )}

        {showCloseButton && (
          <DynamicButton
            loading={isLoading}
            variant="text"
            onClick={handleOnClose}
          >
            <IconRender icon="Close" width={14} color="light" />
          </DynamicButton>
        )}
      </div>

      <div className="flex flex-col items-center justify-center gap-4">
        <Text>{message}</Text>
        {isLoading ? null : (
          <Text variant="h4" weight="bold">
            {formatNumber(amount)}
          </Text>
        )}
      </div>
      {isLoading ? null : (
        <div className="flex w-72">
          <DynamicButton
            variant="contained"
            color="light"
            className="w-full"
            loading={isLoading}
            onClick={onClick}
          >
            {t`CLAIM`}
          </DynamicButton>
        </div>
      )}
    </Dialog>
  );
};

export default ClaimStakeModal;
