import { t } from "@whitelabel-engine/i18n";
import type { ButtonProps } from "@whitelabel-engine/ui/Button";
import TextField from "@whitelabel-engine/ui/TextField";
import type { Colors } from "@whitelabel-engine/ui/utils/colors";
import { preventStopPropagation } from "@whitelabel-engine/ui/utils/prevent";
import type { ChangeEvent, HTMLAttributes, MouseEvent } from "react";
import { type FC } from "react";
import { formatEther, parseEther } from "viem";

import DynamicButton from "../Dynamic/Button";

export type InputQuantityWithPercentageButtonsProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  "onChange"
> & {
  amount?: bigint;
  backgroundColor?: Colors;
  borderColor?: Colors;
  color?: Colors;
  disabled?: boolean;
  initialValue?: bigint;
  inputWrapperClassName?: string;
  isLoading?: boolean;
  maxPerWallet?: bigint;
  minPerWallet?: bigint;
  onChange?: (amount: bigint) => void;
  percentages?: number[];
  rounded?: string;
  selectorColor?: Colors;
  selectorVariant?: ButtonProps["variant"];
};

const calculateAmount = (
  amount: bigint,
  minPerWallet: bigint,
  maxPerWallet: bigint,
) => {
  if (amount <= minPerWallet) {
    return minPerWallet;
  }

  if (amount >= maxPerWallet) {
    return maxPerWallet;
  }

  return amount;
};

const isButtonSelected = (
  amount: bigint,
  maxPerWallet: bigint,
  value: number,
) => {
  if (maxPerWallet === 0n) {
    return false;
  }

  const input =
    ((amount + 1n) * parseEther("100")) / maxPerWallet / parseEther("1");
  const percentage = parseEther((value * 100).toString()) / parseEther("1");

  return input === percentage;
};

const InputQuantityWithPercentageButtons: FC<
  InputQuantityWithPercentageButtonsProps
> = ({
  color = "light",
  selectorColor = "light",
  selectorVariant = "text",
  borderColor = "neutral",
  rounded = "rounded",
  backgroundColor = "overlay",
  amount = 1n,
  minPerWallet = 1n,
  maxPerWallet = 5n,
  isLoading = false,
  disabled = false,
  className = "border-none py-2 px-4 font-serif",
  inputWrapperClassName = "",
  percentages = [0.25, 0.5, 0.75, 1],
  onChange,
}) => {
  const isDisabled = disabled || isLoading || amount === 0n;
  const maxPerWalletMinimum = maxPerWallet <= 1n;
  const isPlusModifierDisabled =
    isDisabled || amount >= maxPerWallet || maxPerWalletMinimum;

  const handleOnAmountChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    if (value === "") {
      return onChange?.(parseEther("1"));
    }

    const amount = parseEther(value || `${minPerWallet}` || "1");
    onChange?.(calculateAmount(amount, minPerWallet, maxPerWallet));
  };

  const handleOnAmountChangeClick = (event: MouseEvent<HTMLButtonElement>) => {
    preventStopPropagation<HTMLButtonElement>(event);
    const newAmount = calculateAmount(maxPerWallet, minPerWallet, maxPerWallet);
    onChange?.(newAmount);
  };

  const handleOnAmountPercentageChangeClick =
    (percentage: number) => (event: MouseEvent<HTMLButtonElement>) => {
      preventStopPropagation<HTMLButtonElement>(event);
      const newAmount = calculateAmount(
        maxPerWallet,
        minPerWallet,
        (maxPerWallet * BigInt(percentage * 100)) / BigInt(100),
      );
      onChange?.(newAmount);
    };

  return (
    <>
      <TextField
        data-testid="input-quantity-with-buttons"
        type="number"
        variant="raw"
        className={["inputNumberNoSpinners", "w-full", className].join(" ")}
        inputWrapperClassName={[
          "w-full",
          "border",
          "border-solid",
          rounded,
          `border-${borderColor}`,
          `bg-${backgroundColor}`,
          inputWrapperClassName,
        ].join(" ")}
        value={formatEther(amount, "wei")}
        min={formatEther(minPerWallet, "wei")}
        max={formatEther(maxPerWallet, "wei")}
        step={formatEther(minPerWallet, "wei")}
        disabled={isDisabled}
        onChange={handleOnAmountChange}
        onClick={preventStopPropagation<HTMLInputElement>}
        color={color}
        endSlot={
          <DynamicButton
            onClick={handleOnAmountChangeClick}
            noShadow
            variant={selectorVariant}
            color={selectorColor}
            className={[`!text-${color}`, "!px-4"].join(" ")}
            disabled={isPlusModifierDisabled}
            size="xsmall"
            data-testid="input-quantity-with-buttons-button-plus"
          >
            {t`Max`}
          </DynamicButton>
        }
      />

      <div className="flex flex-row justify-between gap-2">
        {percentages?.map((value) => (
          <button
            key={value}
            onClick={handleOnAmountPercentageChangeClick(value)}
            disabled={isDisabled}
            className={[
              "border-2",
              "border-solid",
              "border-secondary",
              "!py-2",
              "!px-4",
              "!rounded-none",
              isButtonSelected(amount, maxPerWallet, value)
                ? "bg-secondary"
                : "bg-transparent",
            ].join(" ")}
          >
            {value * 100}%
          </button>
        ))}
      </div>
    </>
  );
};

export default InputQuantityWithPercentageButtons;
