import { t } from "@whitelabel-engine/i18n";
import type { DialogProps } from "@whitelabel-engine/ui/Modals/Dialog";
import Dialog from "@whitelabel-engine/ui/Modals/Dialog";
import Text from "@whitelabel-engine/ui/Text";
import type { Colors } from "@whitelabel-engine/ui/utils/colors";
import { type FC, type MouseEvent } from "react";

import DynamicButton from "../Dynamic/Button";
import DynamicLoadingButton from "../Dynamic/LoadingButton";
import IconRender from "../IconRender";

const ApproveTokenErrorModal: FC<
  DialogProps & {
    borderColor?: Colors;
    color?: Colors;
    dataTestId?: string;
    isLoading?: boolean;
    message?: string;
    onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
    onClose?: (event?: MouseEvent<HTMLButtonElement>) => void;
    open?: boolean;
    reason?: string;
    rounded?: string;
    title?: string;
  }
> = ({
  backgroundColor,
  borderColor = "neutral",
  color = "light",
  dataTestId,
  isLoading,
  message = t`Please contact the support team in Discord`,
  onClick,
  onClose,
  opacity,
  open,
  reason,
  rounded = "rounded-xl",
  title,
  ...props
}) => {
  return (
    <Dialog
      open={open}
      backgroundColor={backgroundColor}
      wrapperClassName={[
        "max-w-[320px]",
        "sm:max-w-[650px]",
        "flex",
        "flex-col",
        "gap-8",
        "justify-center",
        "items-center",
        "border",
        "p-4",
        rounded,
        `border-${borderColor}`,
        `text-${color}`,
      ].join(" ")}
      hideCloseButton
      opacity={opacity}
      data-testid={dataTestId}
      {...props}
    >
      <div className="flex w-full flex-row items-center justify-between gap-4">
        <Text variant="h4" weight="bold">
          {title}
        </Text>

        {isLoading ? null : (
          <DynamicButton
            disabled={isLoading}
            variant="text"
            onClick={onClose}
            className="!p-2"
          >
            <IconRender icon="Close" width={14} color="light" />
          </DynamicButton>
        )}
      </div>

      <div className="flex w-full flex-col px-4">
        {reason ? (
          <Text className="w-full text-center font-serif text-sm sm:text-base">
            {reason}
          </Text>
        ) : null}
        {message ? (
          <Text className="w-full text-center font-serif text-sm sm:text-base">
            {message}
          </Text>
        ) : null}
      </div>

      <div className="flex items-center justify-center">
        <IconRender icon="Discord" color="_cold" width={60} />
      </div>

      <div className="flex w-60 items-center justify-center">
        <DynamicLoadingButton
          onClick={onClick}
          variant="contained"
          color="light"
          className="w-full"
        >{t`Confirm`}</DynamicLoadingButton>
      </div>
    </Dialog>
  );
};

export default ApproveTokenErrorModal;
