"use client";
// import { formatNumber } from "@whitelabel-engine/walletkit/utils/format";
import { t } from "@whitelabel-engine/i18n";
import type { ABIItem, Address } from "@whitelabel-engine/typings/contract";
import type { Network } from "@whitelabel-engine/typings/network";
import LoadingButtonIrregularAnimated from "@whitelabel-engine/ui/Button/LoadingButtonIrregularAnimated";
import Image from "@whitelabel-engine/ui/Image";
import LabelSquared from "@whitelabel-engine/ui/Label/LabelSquared";
import Text from "@whitelabel-engine/ui/Text";
import useActiveWagmi from "@whitelabel-engine/walletkit/hooks/useActiveWagmi";
import { useApproveTokenAllowance } from "@whitelabel-engine/walletkit/hooks/useApproveTokenAllowance";
import {
  useClaim,
  useDeposit,
  useDepositRewards,
  useGetAccountDetails,
  useGetPoolDetails,
  useWithdraw,
} from "@whitelabel-engine/walletkit/hooks/useStakingContract";
import cloneDeep from "lodash.clonedeep";
import { type FC, useState } from "react";
import { formatEther, maxUint256, parseEther } from "viem";

import ConnectForjWalletButton from "../ConnectForjWalletButton";
import useCountdown from "../hooks/useCountdown";
import ApproveTokenModal from "../Modals/ApproveToken";
import ApproveTokenErrorModal from "../Modals/ApproveTokenError";
import ClaimStakeModal from "../Modals/ClaimStake";
import CommitWithdrawDepositStakeModal from "../Modals/CommitWithdrawDepositStake";
import CoumpoundDepositStakeModal from "../Modals/CompoundDepositStake";
import PendingTransactionModal from "../Modals/PendingTransaction";
import SuccessTransactionModal from "../Modals/SuccessTransaction";
import { formatNumber } from "../utils/format";

export type RewardsProjectSectionProps = {
  abi: ABIItem[];
  address: Address;
  networks: Network[];
  selectedNetwork: Network;
  poolData: [BigInt, BigInt, Address];
  // @ts-ignore
  selectedTokenData: Network["tokens"];
};

export type EarnTokenPanelProps = {
  abi: ABIItem[];
  address: Address;
  networks: Network[];
  selectedNetwork: Network;
  poolData: [BigInt, BigInt, Address];
  // @ts-ignore
  selectedTokenData: Network["tokens"];
};

export type RewardsTokenPanelProps = {
  abi: ABIItem[];
  address: Address;
  networks: Network[];
  earningRatePer: string;
  selectedNetwork: Network;
  poolData: [BigInt, BigInt, Address];
  // @ts-ignore
  selectedTokenData: Network["tokens"];
};

const defaultModalState = {
  approveModal: false,
  approveModalError: false,
  commitModal: false,
  commitModalSuccess: false,
  commitModalError: false,
  withdrawModal: false,
  withdrawModalSuccess: false,
  withdrawModalError: false,
  compoundModal: false,
  compoundModalSuccess: false,
  compoundModalError: false,
  claimModal: false,
  claimModalSuccess: false,
  claimModalError: false,
};

export const TimeLock: FC<{ remainingTime: Date; tokenSymbol: string }> = ({
  remainingTime,
  tokenSymbol,
}) => {
  const { days, hours, minutes, seconds, hasEnded } = useCountdown(
    remainingTime,
    true,
  );
  return hasEnded ? null : (
    <div className="bg-accent flex w-full flex-row justify-between p-4">
      <div className="flex w-1/2 flex-col">
        <Text size="lg" weight="bold">{t`Time Remaining:`}</Text>
        <Text
          size="sm"
          weight="light"
        >{t`Your committed $${tokenSymbol} and accrued rewards will be unlocked for withdrawal and claim shortly.`}</Text>
      </div>
      <div className="flex items-center">
        ~{days}D : {hours}H : {minutes}M : {seconds}S
      </div>
    </div>
  );
};

export const EarnTokenPanel: FC<EarnTokenPanelProps> = ({
  abi,
  address,
  networks,
  poolData,
  selectedNetwork,
  selectedTokenData,
}) => {
  const [amount, setAmount] = useState(parseEther("1"));
  const [modalOpenState, setModalOpenState] = useState<
    typeof defaultModalState
  >(cloneDeep(defaultModalState));
  const { isConnected } = useActiveWagmi();
  const { selectedToken, tokenAddress } = useGetPoolDetails(
    address,
    abi,
    networks,
    poolData,
    selectedNetwork,
    selectedTokenData,
  );
  const {
    formattedTokenBalance,
    formattedRewardsBalance,
    tokenBalance,
    formattedDepositedBalance,
    depositedBalance,
    unlockTime,
    refetch,
  } = useGetAccountDetails(address, abi, tokenAddress);
  const { hasEnded } = useCountdown(unlockTime, false);
  const setModalState = (value?: Partial<typeof defaultModalState>) => {
    setModalOpenState({ ...cloneDeep(defaultModalState), ...(value ?? {}) });
  };
  const {
    onDeposit,
    isLoading: isLoadingDeposit,
    error: depositError,
    reset: resetDeposit,
  } = useDeposit(
    abi,
    address,
    () => {
      setModalState({ commitModalSuccess: true });
      refetch();
    },
    () => {
      if (
        depositError?.message
          ?.toLowerCase()
          .includes("user rejected the request")
      ) {
        return;
      }
      setModalState({ commitModalError: true });
      refetch();
    },
  );
  const {
    onWithdraw,
    isLoading: isLoadingWithdraw,
    error: withdrawError,
    reset: resetWithdraw,
  } = useWithdraw(
    abi,
    address,
    () => {
      setModalState({ withdrawModalSuccess: true });
      refetch();
    },
    () => {
      if (
        withdrawError?.message
          ?.toLowerCase()
          .includes("user rejected the request")
      ) {
        return;
      }
      setModalState({ withdrawModalError: true });
      refetch();
    },
  );
  const {
    isApproveRequired,
    onApprove,
    isLoading: isLoadingApproval,
  } = useApproveTokenAllowance(
    tokenAddress,
    address,
    tokenBalance ?? maxUint256,
    () => {
      setModalState({ commitModal: true });
      refetch();
    },
    () => {
      setModalState({ approveModalError: true });
      refetch();
    },
  );
  const isCommitDisabled =
    !formattedTokenBalance || isLoadingApproval || isLoadingDeposit;
  const isWithdrawDisabled =
    +formattedDepositedBalance === 0 || isLoadingWithdraw || !hasEnded;

  const handleOnApprove = () => {
    setModalState();
    onApprove?.();
  };

  const handleOnApproveClose = () => {
    setModalState();
  };

  const handleOnCommit = () => {
    setModalState({ commitModal: true });
  };

  const handleOnCommitContinue = (value: bigint) => {
    if (isApproveRequired) {
      setModalState({ approveModal: true });
      return;
    }
    setAmount(value);
    onDeposit?.(value);
  };

  const handleOnCommitContinueClose = () => {
    setAmount(parseEther("1"));
    setModalState();
  };

  const handleOnWithdraw = () => {
    setModalState({ withdrawModal: true });
  };

  const handleOnClickWithdraw = (value: bigint) => {
    setAmount(value);
    onWithdraw?.(value);
  };

  const handleOnWithdrawClose = () => {
    setModalState();
  };

  const handleOnCloseApproveErrorModal = () => {
    setModalState();
  };

  const handleOnClickApproveErrorModal = () => {
    handleOnCloseApproveErrorModal();
  };

  const handleOnCloseCommitSuccessModal = () => {
    setAmount(parseEther("1"));
    setModalState();
    resetDeposit();
  };

  const handleOnCloseCommitErrorModal = () => {
    setModalState();
  };

  const handleOnClickCommitErrorModal = () => {
    setModalState({ commitModal: true });
  };

  const handleOnCloseWithdrawSuccessModal = () => {
    setModalState();
    resetWithdraw();
  };

  const handleOnCloseWithdrawErrorModal = () => {
    setModalState();
  };

  const handleOnClickWithdrawErrorModal = () => {
    setModalState({ withdrawModal: true });
  };

  return (
    <>
      <div className="bg-primary w-full p-8">
        <div className="mb-12 flex flex-col justify-between gap-4 sm:flex-row">
          <div className="flex max-w-[580px] flex-col">
            <Text variant="h2">
              {t`Earn`}{" "}
              <Text weight="bold" size="3xl" color="secondary">
                ${selectedToken?.symbol}
              </Text>
            </Text>
            <Text variant="h6" size="sm" color="neutral">
              {t`Committed $${selectedToken?.symbol} will accrue rewards based on the annual reward rate. Note that there is a 3-day lock period for $${selectedToken?.symbol} you commit to the reward pool.`}
            </Text>
          </div>

          <div className="flex justify-end sm:justify-center">
            <Image
              alt="forjlogo"
              src="/assets/ForjFIcon.svg"
              width={50}
              height={76}
            />
          </div>
        </div>

        {isConnected && (
          <TimeLock
            tokenSymbol={selectedToken?.symbol}
            remainingTime={unlockTime}
          />
        )}

        <div className="flex min-h-[120px] w-full flex-col justify-between gap-12 sm:flex-row">
          <div className="flex w-full flex-col justify-center gap-4 sm:w-2/4">
            <div className="flex w-full justify-between">
              <Text>{t`$${selectedToken?.symbol} balance`}</Text>
              <Text>{formatNumber(formattedTokenBalance)}</Text>
            </div>
            <div className="flex w-full justify-between">
              <Text>{t`$${selectedToken?.symbol} commited`}</Text>
              <Text>{formatNumber(formattedDepositedBalance)}</Text>
            </div>
          </div>

          <div className="flex w-full flex-col justify-end gap-4 sm:w-2/4">
            <ConnectForjWalletButton
              showChildrenWithWrongNetwork
              color="light"
              weight="bold"
              fullWidth
            >
              <LoadingButtonIrregularAnimated
                size="small"
                fullWidth
                variant="contained"
                color="light"
                onClick={handleOnCommit}
                loading={isLoadingApproval || isLoadingDeposit}
                disabled={isCommitDisabled}
              >
                <Text transform="uppercase" weight="bold">{t`Commit`}</Text>
              </LoadingButtonIrregularAnimated>
              <LoadingButtonIrregularAnimated
                size="small"
                fullWidth
                variant="contained"
                color="light"
                disabled={isWithdrawDisabled}
                loading={isLoadingWithdraw}
                onClick={handleOnWithdraw}
              >
                <Text transform="uppercase" weight="bold">{t`Withdraw`}</Text>
              </LoadingButtonIrregularAnimated>
            </ConnectForjWalletButton>
          </div>
        </div>
      </div>

      {/* Approve Token */}
      <ApproveTokenModal
        open={modalOpenState.approveModal}
        title={t`APPROVE TOKEN`}
        message={t`Allow the transfer of your token to the vault. You'll be asked to approve this transaction from your wallet.`}
        onClick={handleOnApprove}
        onClose={handleOnApproveClose}
        backgroundColor="primary"
      />
      <PendingTransactionModal
        hideCloseButton
        open={isLoadingApproval}
        isLoading={isLoadingApproval}
        title={t`APPROVING TOKEN...`}
        message={t`Approving use of $${selectedToken?.symbol} for committing. This may take a few moments.`}
        backgroundColor="primary"
      />
      <ApproveTokenErrorModal
        open={modalOpenState.approveModalError}
        onClose={handleOnCloseApproveErrorModal}
        onClick={handleOnClickApproveErrorModal}
        backgroundColor="primary"
      />

      {/* Commit Token */}
      <CommitWithdrawDepositStakeModal
        title={t`COMMIT`}
        initAmount={amount}
        minPerWallet={1n}
        maxPerWallet={tokenBalance}
        open={modalOpenState.commitModal}
        isLoading={isLoadingDeposit}
        balance={formattedTokenBalance}
        committed={formattedDepositedBalance}
        claimable={formattedRewardsBalance}
        // @ts-expect-error
        onClick={handleOnCommitContinue}
        onClose={handleOnCommitContinueClose}
        backgroundColor="primary"
        showCloseButton
        tokenIcon={selectedToken?.icon}
        callToActionText={
          formattedDepositedBalance ? t`COMMIT & COMPOUND` : t`COMMIT`
        }
        warningMessage={formattedDepositedBalance ? undefined : ""}
      />
      <SuccessTransactionModal
        open={modalOpenState.commitModalSuccess}
        title={t`SUCCESS COMMIT`}
        message={t`You have successfully committed ${formatNumber(
          +Number(formatEther(amount)).toFixed(2),
        )} $${selectedToken?.symbol} to the pool.`}
        onClose={handleOnCloseCommitSuccessModal}
        onClick={handleOnCloseCommitSuccessModal}
        backgroundColor="primary"
      />
      <ApproveTokenErrorModal
        open={modalOpenState.commitModalError}
        title={t`COMMIT FAILED`}
        reason={t`Commit failed :(`}
        message={t`Please try again or contact the support team on Discord.`}
        onClose={handleOnCloseCommitErrorModal}
        onClick={handleOnClickCommitErrorModal}
        backgroundColor="primary"
      />

      {/* Withdraw Token */}
      <CommitWithdrawDepositStakeModal
        title={t`WITHDRAW`}
        initAmount={amount}
        minPerWallet={1n}
        maxPerWallet={depositedBalance}
        open={modalOpenState.withdrawModal}
        isLoading={isLoadingWithdraw}
        // @ts-expect-error
        onClick={handleOnClickWithdraw}
        onClose={handleOnWithdrawClose}
        showCloseButton
        balance={formattedTokenBalance}
        committed={formattedDepositedBalance}
        claimable={formattedRewardsBalance}
        tokenIcon={selectedToken?.icon}
        backgroundColor="primary"
        callToActionText={t`WITHDRAW & CLAIM`}
        warningMessage={t`Your Rewards will be automatically claimed and transferred to your wallet.`}
      />
      <SuccessTransactionModal
        open={modalOpenState.withdrawModalSuccess}
        title={t`SUCCESS WITHDRAW`}
        message={t`Withdrawal successful, check your wallet for withdrawn $${selectedToken?.symbol} and rewards.`}
        onClose={handleOnCloseWithdrawSuccessModal}
        onClick={handleOnCloseWithdrawSuccessModal}
        backgroundColor="primary"
      />
      <ApproveTokenErrorModal
        open={modalOpenState.withdrawModalError}
        title={t`WITHDRAW FAILED`}
        reason={t`Withdraw failed :(`}
        message={t`Please try again or contact the support team on Discord.`}
        onClose={handleOnCloseWithdrawErrorModal}
        onClick={handleOnClickWithdrawErrorModal}
        backgroundColor="primary"
      />
    </>
  );
};

export const RewardsTokenPanel: FC<RewardsTokenPanelProps> = ({
  address,
  abi,
  networks,
  earningRatePer,
  poolData,
  selectedNetwork,
  selectedTokenData,
}) => {
  const [amount, setAmount] = useState<bigint>(parseEther("0"));
  const [modalOpenState, setModalOpenState] = useState<
    typeof defaultModalState
  >(cloneDeep(defaultModalState));
  const { selectedToken, tokenAddress } = useGetPoolDetails(
    address,
    abi,
    networks,
    poolData,
    selectedNetwork,
    selectedTokenData,
  );
  const {
    dailyRewards,
    rewardsBalance,
    formattedRewardsBalance,
    formattedDepositedBalance,
    unlockTime,
    refetch,
  } = useGetAccountDetails(address, abi, tokenAddress);
  const { hasEnded } = useCountdown(unlockTime, false);
  const setModalState = (value?: Partial<typeof defaultModalState>) => {
    setModalOpenState({ ...cloneDeep(defaultModalState), ...(value ?? {}) });
  };
  const {
    onDepositRewards,
    isLoading: isLoadingDepositRewards,
    error: depositRewardsError,
    reset: resetDepositRewards,
  } = useDepositRewards(
    abi,
    address,
    () => {
      setModalState({ compoundModalSuccess: true });
      refetch();
    },
    () => {
      if (
        depositRewardsError?.message
          ?.toLowerCase()
          .includes("user rejected the request")
      ) {
        return;
      }
      setModalState({ compoundModalError: true });
      refetch();
    },
  );
  const {
    onClaim,
    isLoading: isLoadingClaim,
    error: claimError,
    reset: resetClaim,
  } = useClaim(
    abi,
    address,
    () => {
      setModalState({ claimModalSuccess: true });
      refetch();
    },
    () => {
      if (
        claimError?.message?.toLowerCase().includes("user rejected the request")
      ) {
        return;
      }
      setModalState({ claimModalError: true });
      refetch();
    },
  );
  const formattedDailyRewards = formatEther(dailyRewards, "wei");
  const isCompoundDisabled =
    +formattedRewardsBalance === 0 || isLoadingDepositRewards;
  const isClaimDisabled =
    +formattedRewardsBalance === 0 || isLoadingClaim || !hasEnded;

  const handleOnDepositRewards = () => {
    setModalState({ compoundModal: true });
  };

  const handleOnDepositRewardsClose = () => {
    setModalState();
  };

  const handleOnDepositRewardsContinue = () => {
    onDepositRewards();
  };

  const handleOnCloseOnDepositRewardsSuccessModal = () => {
    setModalState();
  };

  const handleOnCloseOnDepositRewardsErrorModal = () => {
    setModalState();
    resetDepositRewards();
  };

  const handleOnClickOnDepositRewardsErrorModal = () => {
    setModalState({ compoundModal: true });
  };

  const handleOnClaim = () => {
    setModalState({ claimModal: true });
  };

  const handleOnClaimClose = () => {
    setModalState();
  };

  const handleOnClaimContinue = () => {
    setAmount(rewardsBalance);
    onClaim();
  };

  const handleOnCloseOnClaimSuccessModal = () => {
    setAmount(parseEther("0"));
    setModalState();
    resetClaim();
  };

  const handleOnCloseOnClaimErrorModal = () => {
    setModalState();
  };

  const handleOnClickOnClaimErrorModal = () => {
    setModalState({ claimModal: true });
  };

  return (
    <>
      <div className="bg-primary relative flex max-h-[328px] w-full max-w-96 flex-col justify-between gap-4 p-8">
        <LabelSquared
          spacing="small"
          size="large"
          color="success"
          weight="bold"
          className="!text-primary absolute right-0 top-0"
        >
          {t`Rewards`}
        </LabelSquared>
        <div className="flex flex-col gap-4">
          <div className="flex w-full flex-col">
            <Text color="neutral" className="mb-1">
              {t`Earning Rate`}
            </Text>
            <Text weight="bold">
              {formatNumber(formattedDailyRewards || "0")} / {earningRatePer}
            </Text>
          </div>
          <div className="flex w-full flex-col">
            <Text color="neutral" className="mb-1">
              {t`Claimable Rewards`}
            </Text>
            <Text weight="bold">
              {formatNumber(formattedRewardsBalance || "0")}
            </Text>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <ConnectForjWalletButton
            showChildrenWithWrongNetwork
            color="light"
            weight="bold"
            fullWidth
          >
            <LoadingButtonIrregularAnimated
              size="small"
              fullWidth
              variant="contained"
              color="light"
              disabled={isCompoundDisabled}
              onClick={handleOnDepositRewards}
              loading={isLoadingDepositRewards}
            >
              <Text transform="uppercase" weight="bold">{t`Compound`}</Text>
            </LoadingButtonIrregularAnimated>
            <LoadingButtonIrregularAnimated
              size="small"
              fullWidth
              variant="contained"
              color="light"
              disabled={isClaimDisabled}
              onClick={handleOnClaim}
              loading={isLoadingClaim}
            >
              <Text transform="uppercase" weight="bold">{t`Claim`}</Text>
            </LoadingButtonIrregularAnimated>
          </ConnectForjWalletButton>
        </div>
      </div>

      {/* Compound  */}
      <CoumpoundDepositStakeModal
        showCloseButton
        open={modalOpenState.compoundModal}
        isLoading={isLoadingDepositRewards}
        committed={formattedDepositedBalance}
        rewards={formattedRewardsBalance}
        tokenIcon={selectedToken?.icon}
        onClose={handleOnDepositRewardsClose}
        onClick={handleOnDepositRewardsContinue}
      />
      <SuccessTransactionModal
        open={modalOpenState.compoundModalSuccess}
        title={t`COMPOUND SUCCESSFUL`}
        message={t`You have successfully compounded your committed $${selectedToken?.symbol}.`}
        onClose={handleOnCloseOnDepositRewardsSuccessModal}
        onClick={handleOnCloseOnDepositRewardsSuccessModal}
        backgroundColor="primary"
      />
      <ApproveTokenErrorModal
        open={modalOpenState.compoundModalError}
        title={t`COMPOUND FAILED`}
        reason={t`Compound failed :(`}
        message={t`Please try again or contact the support team on Discord.`}
        onClose={handleOnCloseOnDepositRewardsErrorModal}
        onClick={handleOnClickOnDepositRewardsErrorModal}
        backgroundColor="primary"
      />

      {/* Claim  */}
      <ClaimStakeModal
        showCloseButton
        open={modalOpenState.claimModal}
        amount={formattedRewardsBalance}
        title={isLoadingClaim ? t`CLAIMING...` : t`CLAIM`}
        isLoading={isLoadingClaim}
        message={
          isLoadingClaim
            ? t`Your rewards are on their way to your wallet. This may take a few moments.`
            : t`Rewards`
        }
        onClose={handleOnClaimClose}
        onClick={handleOnClaimContinue}
      />
      <SuccessTransactionModal
        open={modalOpenState.claimModalSuccess}
        isLoading={isLoadingClaim}
        title={t`CLAIM SUCCESSFUL`}
        message={t`You have successfully claimed ${formatNumber(
          +Number(formatEther(amount)).toFixed(2),
        )} $${selectedToken?.symbol}. Check your wallet to see your rewards.`}
        onClose={handleOnCloseOnClaimSuccessModal}
        onClick={handleOnCloseOnClaimSuccessModal}
        backgroundColor="primary"
      />
      <ApproveTokenErrorModal
        open={modalOpenState.claimModalError}
        title={t`CLAIM FAILED`}
        reason={t`Claim failed :(`}
        message={t`Please try again or contact the support team on Discord.`}
        onClose={handleOnCloseOnClaimErrorModal}
        onClick={handleOnClickOnClaimErrorModal}
        backgroundColor="primary"
      />
    </>
  );
};

export const RewardsProjectSection: FC<RewardsProjectSectionProps> = ({
  abi,
  address,
  networks,
  poolData,
  selectedNetwork,
  selectedTokenData,
}) => {
  const { totalTokenCommitedFormatted, formattedPoolAPR, selectedToken } =
    useGetPoolDetails(
      address,
      abi,
      networks,
      poolData,
      selectedNetwork,
      selectedTokenData,
    );

  return (
    <div className="flex w-full flex-col justify-between p-4 sm:flex-row">
      <div className="mb-4 flex flex-col gap-4 sm:mb-0">
        <Text variant="h1" className="!text-4xl sm:text-5xl">
          {t`V2 $${selectedToken?.symbol} reward pool`}
        </Text>
        <Text variant="span" size="md" color="neutral">
          {t`Commit your $${selectedToken?.symbol} to receive $${selectedToken?.symbol} rewards`}
        </Text>
      </div>

      <div className="flex flex-col justify-end gap-2 text-center sm:text-right">
        <Text>{t`Annual Rewards Rate:`}</Text>
        <Text weight="bold" size="4xl" className="mb-2 sm:mb-0">
          {formatNumber(formattedPoolAPR)}%
        </Text>
        <Text>{t`Total $${selectedToken?.symbol} committed:`}</Text>
        <Text weight="bold" size="4xl">
          {formatNumber(totalTokenCommitedFormatted)}
        </Text>
      </div>
    </div>
  );
};

export default RewardsProjectSection;
