import type { NetworkToken } from "@whitelabel-engine/db";
import type { ABIItem, Address } from "@whitelabel-engine/typings/contract";
import type { Network } from "@whitelabel-engine/typings/network";
import { useCallback } from "react";
import { formatEther } from "viem";
import { useBalance } from "wagmi";

import { AddressZero } from "../utils/constant";
import useActiveWagmi from "./useActiveWagmi";
import useContractReadWithTransactionWatch from "./useContractReadWithTransactionWatch";
import useContractWriteWithTransactionWatch from "./useContractWriteWithTransactionWatch";
import { usePreparedContractABI } from "./usePreparationContract";
import useWatchContract from "./useWatchContract";

export const useDeposit = (
  abi?: ABIItem[] | string,
  address?: string,
  onSuccess?: Function,
  onError?: Function,
  enableSmartContractInteraction: boolean = true,
) => {
  const { chainId } = useActiveWagmi();
  const contract = usePreparedContractABI(abi, address);
  const { write, ...contractWriteResults } =
    useContractWriteWithTransactionWatch(
      {
        mode: "recklesslyUnprepared",
        ...contract,
        chainId,
        functionName: "deposit",
        enabled: enableSmartContractInteraction,
      },
      onSuccess,
      onError,
    );

  const onDeposit = useCallback(
    (amount: bigint) => {
      // @ts-ignore
      return write?.({
        args: [amount],
      });
    },
    [write],
  );

  return {
    onDeposit,
    ...contractWriteResults,
  };
};

// compound
export const useDepositRewards = (
  abi?: ABIItem[] | string,
  address?: string,
  onSuccess?: Function,
  onError?: Function,
  enableSmartContractInteraction: boolean = true,
) => {
  const { chainId } = useActiveWagmi();
  const contract = usePreparedContractABI(abi, address);
  const { write, ...contractWriteResults } =
    useContractWriteWithTransactionWatch(
      enableSmartContractInteraction
        ? {
            mode: "recklesslyUnprepared",
            ...contract,
            chainId,
            functionName: "depositRewards",
          }
        : {},
      onSuccess,
      onError,
    );

  const onDepositRewards = useCallback(() => {
    // @ts-ignore
    return write?.();
  }, [write]);

  return {
    onDepositRewards,
    ...contractWriteResults,
  };
};

export const useWithdraw = (
  abi?: ABIItem[] | string,
  address?: string,
  onSuccess?: Function,
  onError?: Function,
  enableSmartContractInteraction: boolean = true,
) => {
  const { chainId } = useActiveWagmi();
  const contract = usePreparedContractABI(abi, address);
  const { write, ...contractWriteResults } =
    useContractWriteWithTransactionWatch(
      {
        mode: "recklesslyUnprepared",
        ...contract,
        chainId,
        functionName: "withdraw",
        enabled: enableSmartContractInteraction,
      },
      onSuccess,
      onError,
    );

  const onWithdraw = useCallback(
    (amount: bigint) => {
      // @ts-ignore
      return write?.({
        args: [amount],
      });
    },
    [write],
  );

  return {
    onWithdraw,
    ...contractWriteResults,
  };
};

export const useClaim = (
  abi?: ABIItem[] | string,
  address?: string,
  onSuccess?: Function,
  onError?: Function,
  enableSmartContractInteraction: boolean = true,
) => {
  const { chainId } = useActiveWagmi();
  const contract = usePreparedContractABI(abi, address);
  const { write, ...contractWriteResults } =
    useContractWriteWithTransactionWatch(
      {
        mode: "recklesslyUnprepared",
        ...contract,
        chainId,
        functionName: "claim",
        enabled: enableSmartContractInteraction,
      },
      onSuccess,
      onError,
    );
  const onClaim = useCallback(() => {
    // @ts-ignore
    return write?.();
  }, [write]);

  return {
    onClaim,
    ...contractWriteResults,
  };
};

export const useGetPoolDetails = (
  address: Address,
  abi: ABIItem[],
  networks: Network[],
  defaultValue: [BigInt, BigInt, Address],
  _selectedNetwork: Network,
  _selectedTokenData: NetworkToken,
) => {
  const { chainId, isConnected } = useActiveWagmi();
  const enableSmartContractInteraction = Boolean(address && abi && isConnected);
  const {
    data = defaultValue,
    isLoading,
    refetch,
    ...rest
  } = useContractReadWithTransactionWatch(
    {
      abi,
      address,
      chainId,
      functionName: "getPoolDetails",
    },
    enableSmartContractInteraction,
    true,
  );

  const [totalTokenCommited, yearlyRewardsRate, tokenAddress] = data ?? [
    BigInt(0),
    BigInt(0),
    AddressZero,
  ];

  const totalTokenCommitedFormatted = formatEther(totalTokenCommited);

  const selectedNetwork =
    networks?.find?.(({ chainId: _chainId }) => _chainId === `${chainId}`) ??
    _selectedNetwork;
  // @ts-expect-error
  const selectedToken = (selectedNetwork?.tokens?.find?.(
    // @ts-expect-error
    ({ address }) => tokenAddress === address,
  ) ?? _selectedTokenData) as NetworkToken;

  const poolAPR =
    totalTokenCommited === 0n
      ? 0
      : (100 * Number(formatEther(yearlyRewardsRate))) /
        Number(formatEther(totalTokenCommited));

  const formattedPoolAPR = poolAPR.toString();

  return {
    totalTokenCommited,
    poolAPR,
    totalTokenCommitedFormatted,
    formattedPoolAPR,
    tokenAddress,
    chainId,
    selectedNetwork,
    selectedToken,
    ...rest,
    isLoading,
  };
};

export const useGetAccountDetails = (
  address: Address,
  abi: ABIItem[],
  tokenAddress: Address,
) => {
  const { account, chainId, isNetworkSupported } = useActiveWagmi();
  const enableSmartContractInteraction = Boolean(
    isNetworkSupported && account && address && abi,
  );
  const commonContractRead = {
    abi,
    address,
    chainId,
  };
  const {
    data,
    queryKey,
    isLoading,
    refetch: refetchAccountDetails,
    ...rest
  } = useContractReadWithTransactionWatch(
    {
      ...commonContractRead,
      args: [account],
      functionName: "getAccountDetails",
    },
    enableSmartContractInteraction,
    true,
  );

  const [dailyRewards, rewardsBalance, depositedBalance, unlockTime] = data ?? [
    BigInt(0),
    BigInt(0),
    BigInt(0),
    BigInt(0),
    BigInt(0),
  ];

  const {
    data: tokenBalance,
    queryKey: queryKeyBalance,
    refetch: refetchBalance,
  } = useBalance({
    address: account,
    token: tokenAddress,
  });

  const refetch = useCallback(() => {
    refetchAccountDetails();
    return refetchBalance();
  }, [refetchAccountDetails, refetchBalance]);

  useWatchContract(queryKeyBalance, refetch);

  const formattedRewardsRate = +Number(formatEther(dailyRewards ?? 0n)).toFixed(
    2,
  );
  const formattedRewardsBalance = +Number(
    formatEther(rewardsBalance ?? 0n),
  ).toFixed(2);
  const formattedDepositedBalance = +Number(
    formatEther(depositedBalance ?? 0n),
  ).toFixed(2);
  const formattedTokenBalance = +Number(
    formatEther(tokenBalance?.value ?? 0n),
  ).toFixed(2);

  const _unlockTimeDate = new Date(Number(unlockTime?.toString()) * 1000);
  const _unlockTime = isNaN(_unlockTimeDate.getTime())
    ? new Date(0)
    : _unlockTimeDate;

  return {
    tokenBalance: tokenBalance?.value ?? 0n,
    depositedBalance,
    formattedTokenBalance,
    formattedDepositedBalance,
    formattedRewardsBalance,
    formattedRewardsRate,
    unlockTime: _unlockTime,
    rewardsBalance,
    dailyRewards,
    refetch,
    refetchAccountDetails,
    refetchBalance,
    ...rest,
    isLoading,
  };
};
