import type {
  ABIItem,
  Address,
  MerkleProof,
} from "@whitelabel-engine/typings/contract";
import { useMemo } from "react";

import { AddressZero } from "../utils/constant";
import { getAddressMerkleProof } from "../utils/validate";

let consoleWarning = false;

const merkleProofToObject = (
  merkleProof?: MerkleProof | string,
): MerkleProof =>
  typeof merkleProof === "string" && merkleProof !== "undefined"
    ? JSON.parse(merkleProof)
    : merkleProof;

export const useMerkleProof = (
  _merkleProof?: MerkleProof | string | (MerkleProof | string)[],
  _account?: string,
) => {
  const account = (_account ?? "") as Address;
  const merkleProof = useMemo(() => {
    if (!_merkleProof) {
      return undefined;
    }

    if (Array.isArray(_merkleProof)) {
      return _merkleProof.map(merkleProofToObject);
    }

    return merkleProofToObject(_merkleProof);
  }, [_merkleProof]);

  const { proof, root } = useMemo(() => {
    if (Array.isArray(merkleProof)) {
      const allProofs = merkleProof.map((proof) =>
        getAddressMerkleProof(proof, account),
      );

      const proof = allProofs.find(({ proof }) => {
        return Boolean(proof);
      });
      return proof ? proof : allProofs[0];
    }

    return getAddressMerkleProof(merkleProof!, account);
  }, [merkleProof, account]);

  const tierNumber = useMemo(() => Number(proof?.tier), [proof]);

  if (!account && !consoleWarning) {
    console.warn("useMerkleProof is efficient with an account");
    consoleWarning = true;
  }

  return useMemo(
    () => ({ merkleProof, tierNumber, proof, root }),
    [_merkleProof, account],
  );
};

export const usePreparedContractABI = (
  _abi?: ABIItem[] | string,
  _address?: string,
) => {
  return useMemo(
    () => ({
      abi: (typeof _abi === "string" ? JSON.parse(_abi) : _abi) as ABIItem[],
      address: (_address ?? AddressZero) as Address,
    }),
    [_abi, _address],
  );
};
