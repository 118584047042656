import type { FC } from "react";

import _BlankIcon from "./_Blank";
import type { IconProps } from "./utils";

const InfoIcon: FC<IconProps> = (props) => {
  return (
    <_BlankIcon viewBox="0 0 33 33" {...props}>
      <path d="M16.25 0C13.0361 0 9.89429 0.953046 7.22199 2.73862C4.54969 4.52419 2.46689 7.06209 1.23696 10.0314C0.00704087 13.0007 -0.314763 16.268 0.312247 19.4202C0.939256 22.5724 2.48692 25.4679 4.75952 27.7405C7.03213 30.0131 9.9276 31.5607 13.0798 32.1878C16.232 32.8148 19.4993 32.493 22.4686 31.263C25.4379 30.0331 27.9758 27.9503 29.7614 25.278C31.547 22.6057 32.5 19.4639 32.5 16.25C32.4951 11.9417 30.7815 7.81131 27.7351 4.7649C24.6887 1.7185 20.5583 0.00487993 16.25 0ZM16.25 30C13.5305 30 10.8721 29.1936 8.61092 27.6827C6.34974 26.1718 4.58737 24.0244 3.54666 21.5119C2.50596 18.9994 2.23366 16.2347 2.76421 13.5675C3.29476 10.9003 4.60432 8.45025 6.52729 6.52728C8.45026 4.60431 10.9003 3.29475 13.5675 2.7642C16.2348 2.23366 18.9994 2.50595 21.5119 3.54666C24.0244 4.58736 26.1718 6.34973 27.6827 8.61091C29.1936 10.8721 30 13.5305 30 16.25C29.9959 19.8955 28.5459 23.3904 25.9682 25.9682C23.3904 28.5459 19.8955 29.9959 16.25 30ZM18.75 23.75C18.75 24.0815 18.6183 24.3995 18.3839 24.6339C18.1495 24.8683 17.8315 25 17.5 25H16.25C15.9185 25 15.6005 24.8683 15.3661 24.6339C15.1317 24.3995 15 24.0815 15 23.75V16.25C14.6685 16.25 14.3505 16.1183 14.1161 15.8839C13.8817 15.6495 13.75 15.3315 13.75 15C13.75 14.6685 13.8817 14.3505 14.1161 14.1161C14.3505 13.8817 14.6685 13.75 15 13.75H16.25C16.5815 13.75 16.8995 13.8817 17.1339 14.1161C17.3683 14.3505 17.5 14.6685 17.5 15V22.5C17.8315 22.5 18.1495 22.6317 18.3839 22.8661C18.6183 23.1005 18.75 23.4185 18.75 23.75ZM14.0625 9.375C14.0625 9.00416 14.1725 8.64165 14.3785 8.33331C14.5845 8.02496 14.8774 7.78464 15.22 7.64273C15.5626 7.50081 15.9396 7.46368 16.3033 7.53603C16.667 7.60837 17.0011 7.78695 17.2633 8.04917C17.5256 8.3114 17.7041 8.64549 17.7765 9.0092C17.8488 9.37292 17.8117 9.74992 17.6698 10.0925C17.5279 10.4351 17.2875 10.728 16.9792 10.934C16.6709 11.14 16.3083 11.25 15.9375 11.25C15.4402 11.25 14.9633 11.0525 14.6117 10.7008C14.2601 10.3492 14.0625 9.87228 14.0625 9.375Z" />
    </_BlankIcon>
  );
};
export default InfoIcon;
