// CONVENTION isFoo -> boolean
import type {
  Address,
  MerkleProof,
  ProofMap,
} from "@whitelabel-engine/typings/contract";
import { getAddress, isAddressEqual } from "viem";

export const isBigIntish = (value: any) => {
  return ["bigint", "number"].includes(typeof value);
};

export const isEmptyObj = (obj: any) =>
  obj !== null &&
  obj !== undefined &&
  Object.entries(obj).length === 0 &&
  obj.constructor === Object;

export const isEmptyValue = (text: string) =>
  typeof text === "bigint"
    ? BigInt(text) === BigInt(0)
    : text === "" || text.replace(/0/g, "").replace(/\./, "") === "";

export function isSameAddress(address1: string, address2: string): boolean {
  return isAddressEqual(getAddress(address1), getAddress(address2));
}

export function findAddressInMerkleProof(address: Address) {
  return function _findAddressInMerkleProof(proof: ProofMap) {
    return (
      proof?.[address] ??
      proof?.[getAddress?.(address)] ??
      proof?.[`${address}`.toLowerCase()]
    );
  };
}

export const getAddressMerkleProof = (
  merkleTree: MerkleProof,
  address: Address,
) => {
  if (!merkleTree || !address) {
    return { proof: null, root: null };
  }

  const { proofs, root } = merkleTree;

  if (Array.isArray(proofs)) {
    const userProof = proofs.find(findAddressInMerkleProof(address));

    return {
      proof: findAddressInMerkleProof(address)(userProof),
      root,
    };
  }

  const userProof = findAddressInMerkleProof(address)(proofs);

  return { proof: userProof, root };
};
